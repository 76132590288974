<script>
import store from "@/state/store";
var state = store._modules.root._children.auth.state;

export default {
  data() {
    return {
      empLoans: [],
      emps: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchEmpLoans: null,
      EmpLoansSearchMode: false,
      searchModel: "",
      state: state,
      // modal data
      addAsset: [{}],
      currentAssets: [],
      // appprices:[]
    };
  },
  methods: {
    addEmpLoan() {
      console.log("**********####", this.state);

      this.http
        .post("emps/loans", {
          emp_id: parseInt(this.$route.params.id),
          accounts: this.addAsset,
        })
        .then(() => {
          this.addAsset = [{}];
          this.get(this.page);
          this.getCashAccount();
        });
    },
    getCashAccount() {
      this.http.post("transactions/cash-accounts", {}).then((res) => {
        this.currentAssets = res.data;
      });
    },
    deleteOption(index) {
      this.addAsset.splice(index, 1);
    },
    get(page) {
      console.log(page);
      this.http
        .post("emps/emp/loans/pagination", {
          limit: this.limit,
          page: page,
          emp_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.empLoans = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getCashAccount();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("emp_loans.total_balance") }}</th>
                  <th scope="col">{{ $t("emp_loans.paid_balance") }}</th>
                  <th scope="col">{{ $t("emp_loans.created") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in empLoans" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <!-- <td>{{ app.file_date?.split("T")[0] }}</td> -->
                  <td>{{ parseFloat(app?.total_balance).toLocaleString() }}</td>
                  <td>{{ parseFloat(app?.paid_balance).toLocaleString() }}</td>
                  <td>{{ app.created.split("T")[0] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->
  <!--Start Add Modal-->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <form @submit.prevent="addEmpLoan()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("emp_loans.title") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              style="margin: 0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="asset_id">{{
                  $t("emp_loans.loans_options")
                }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  type="button"
                  @click="addAsset.push({})"
                  class="btn float-end"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div
              style="padding: 1rem; background: #f6f4f4"
              v-for="(asset, index) in addAsset"
              :key="asset"
            >
              <div class="row my-3">
                <div class="d-flex justify-content-between">
                  <select
                    v-model="asset.account"
                    class="form-select mb-2"
                    id="asset_id"
                    :placeholder="$t('emps.account')"
                  >
                    <option
                      :disabled="assets?.balance == 0"
                      :style="{
                        backgroundColor: assets?.balance == 0 ? '#f1f1f1' : '',
                      }"
                      v-for="assets in currentAssets"
                      :key="assets"
                      :value="assets"
                    >
                      {{
                        $i18n.locale == "ar"
                          ? `(${assets?.name}) (${parseFloat(
                              assets?.balance
                            ).toLocaleString()})`
                          : `(${assets?.name_en}) (${parseFloat(
                              assets?.balance
                            ).toLocaleString()})`
                      }}
                    </option>
                  </select>
                  <input
                    :style="{
                      borderColor:
                        parseFloat(
                          asset?.account?.balance ? asset?.account?.balance : 1
                        ) <
                        parseFloat(
                          asset.total_balance ? asset.total_balance : 0
                        )
                          ? 'red'
                          : '',
                    }"
                    v-model="asset.total_balance"
                    type="number"
                    class="form-control mb-2 mx-1"
                    :placeholder="$t('emps.amount')"
                  />
                  <span class="mx-2">
                    <button
                      class="btn btn-danger"
                      @click="deleteOption(index)"
                      v-if="index > 0"
                    >
                      <i class="bx bx-trash"></i>
                    </button>
                    <button v-else disabled class="btn btn-danger">
                      <i class="bx bx-trash"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              :disabled="
                !addAsset[0]?.hasOwnProperty('account') &&
                !addAsset[0]?.hasOwnProperty('total_balance')
              "
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->
  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped></style>
