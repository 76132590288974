<script>
import store from "@/state/store";
var state = store._modules.root._children.auth.state;

export default {
  data() {
    return {
      empSalaryItems: [],
      allawanceLevels: [
        {
          name: "الأجر الإبتدائي",
          name_en: "Basic Salary",
          value: 0,
        },
        {
          name: "علاوة 1",
          name_en: "Allawance Level 1",
          value: 1,
        },
        {
          name: "علاوة 2",
          name_en: "Allawance Level 2",
          value: 2,
        },
        {
          name: "علاوة 3",
          name_en: "Allawance Level 3",
          value: 3,
        },
        {
          name: "علاوة 4",
          name_en: "Allawance Level 4",
          value: 4,
        },
        {
          name: "علاوة 5",
          name_en: "Allawance Level 5",
          value: 5,
        },
        {
          name: "علاوة 6",
          name_en: "Allawance Level 6",
          value: 6,
        },
      ],
      emp: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchEmpFiles: null,
      EmpFilesSearchMode: false,
      searchModel: "",
      state: state,
      // appprices:[]
    };
  },
  methods: {
    get() {
      this.http
        .post("salary-items/emp/items", {
          emp_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.empSalaryItems = res.data;
          this.emp = res.employee;
        });
    },
  },
  created() {
    this.get();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input
                    @keyup="search()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                    style="background-color: #2a3042 !important"
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                class="btn-close text-light"
                v-if="EmpFilesSearchMode"
                @click="cancelappsearchMode()"
              ></button>
              <!-- <button
        type="button"
        @click="addEmpFiles()"
        class="btn btn-light float-end mb-4"
      >
        <span
          class="bx bxs-plus-circle float-end fa-2x"
          style="color: #2a3042 !important"
        ></span>
      </button> -->
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("emp_salary_items.name") }}</th>
                  <th scope="col">{{ $t("emp_salary_items.value") }}</th>
                  <!-- <th scope="col">{{ $t("emp_files.operations") }}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in empSalaryItems" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? emp?.selected_allawance == 0 &&
                          app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[0].name
                          : emp?.selected_allawance == 1 &&
                            app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[1].name
                          : emp?.selected_allawance == 2 &&
                            app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[2].name
                          : emp?.selected_allawance == 3 &&
                            app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[3].name
                          : emp?.selected_allawance == 4 &&
                            app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[4].name
                          : emp?.selected_allawance == 5 &&
                            app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[5].name
                          : emp?.selected_allawance == 6 &&
                            app?.salary_item?.salary_item_type == 1
                          ? allawanceLevels[6].name
                          : app?.salary_item?.name
                        : emp?.selected_allawance == 0 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[0].name_en
                        : emp?.selected_allawance == 1 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[1].name_en
                        : emp?.selected_allawance == 2 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[2].name_en
                        : emp?.selected_allawance == 3 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[3].name_en
                        : emp?.selected_allawance == 4 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[4].name_en
                        : emp?.selected_allawance == 5 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[5].name_en
                        : emp?.selected_allawance == 6 &&
                          app?.salary_item?.salary_item_type == 1
                        ? allawanceLevels[6].name_en
                        : app?.salary_item?.name_en
                    }}
                  </td>
                  <td>
                    {{
                      emp?.selected_allawance == 0 &&
                      app?.salary_item?.salary_item_type == 1
                        ? parseFloat(app?.value).toLocaleString()
                        : emp?.selected_allawance == 1 &&
                          app?.salary_item?.salary_item_type == 1
                        ? parseFloat(app?.value1).toLocaleString()
                        : emp?.selected_allawance == 2 &&
                          app?.salary_item?.salary_item_type == 1
                        ? parseFloat(app?.value2).toLocaleString()
                        : emp?.selected_allawance == 3 &&
                          app?.salary_item?.salary_item_type == 1
                        ? parseFloat(app?.value3).toLocaleString()
                        : emp?.selected_allawance == 4 &&
                          app?.salary_item?.salary_item_type == 1
                        ? parseFloat(app?.value4).toLocaleString()
                        : emp?.selected_allawance == 5 &&
                          app?.salary_item?.salary_item_type == 1
                        ? parseFloat(app?.value5).toLocaleString()
                        : parseFloat(app?.value).toLocaleString()
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
