<script>
export default {
  data() {
    return {
      empWorkDays: [],
      workDays: [{}],
      days: [],
    };
  },
  methods: {
    addEmpWorkDays() {
      this.http
        .post("emps/workdays", {
          emp_id: [parseInt(this.$route.params.id)],
          workdays: this.workDays,
        })
        .then(() => {
          this.workDays = [{}];
          this.getEmpWorkDays();
        });
    },
    getEmpWorkDays() {
      this.http
        .post("emps/get-workdays", {
          emp_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.empWorkDays = res.data;
          console.log(this.empWorkDays);
        });
    },
    getWeekDays() {
      this.http.get("emps/days").then((res) => {
        this.days = res.data;
      });
    },
    deleteEmpWorkDays(app) {
      this.http
        .post("emps/delete-workdays", {
          emp_id: parseInt(this.$route.params.id),
          workdays_id: app.id,
        })
        .then((res) => {
          if (res.data) {
            this.getEmpWorkDays();
          }
        });
    },
  },
  created() {
    this.getEmpWorkDays();
    this.getWeekDays();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#addWordDays"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("emps.day") }}</th>
                  <!-- <th scope="col">{{ $t("emp_files.created") }}</th> -->
                  <!-- <th scope="col">{{ $t("emp_files.updated") }}</th> -->
                  <th scope="col">{{ $t("emp_files.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in empWorkDays" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? app?.workday?.day
                        : app?.workday?.day_en
                    }}
                  </td>
                  <!-- <td>{{ app.created.split("T")[0] }}</td> -->
                  <!-- <td>{{ app.updated.split("T")[0] }}</td> -->

                  <td class="d-flex">
                    <a
                      class="btn btn-danger"
                      href="javascript: void(0);"
                      role="button"
                      @click="deleteEmpWorkDays(app)"
                      >{{ $t("popups.delete") }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--Start Add Work Days Modal-->
  <div
    class="modal fade"
    id="addWordDays"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addEmpWorkDays()">
          <div class="modal-header" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("emps.add-work-day") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              style="margin: 0"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="col d-flex justify-content-end">
              <button
                type="button"
                @click="workDays.push({})"
                class="btn float-end"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
            <div v-for="day in workDays" :key="day">
              <label for="salary_item">{{ $t("emps.work-day") }}</label>
              <select
                required
                v-model="day.id"
                class="form-select mb-2"
                id="salary_item"
              >
                <option v-for="d in days" :key="d" :value="d.id">
                  {{ $i18n.locale == "ar" ? d.day : d.day_en }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              data-bs-dismiss="modal"
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Work Days Modal-->

  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped></style>
