<script>
import Layout from "../../../../layouts/main";

import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
import EmployeeFiles from "../EmployeeFiles/index.vue";
import EmpWorkdays from "../EmpWorkdays/index.vue";
import EmpsSalaryItems from "../EmpSalaryItems/index.vue";
import EmployeeLoans from "../EmployeeLoans/index.vue";
// import EmployeeFiles from "./EmployeeFiles/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    // PageHeader,
    EmployeeFiles,
    EmpWorkdays,
    EmpsSalaryItems,
    EmployeeLoans,
  },
  data() {
    return {
      employee: {},
    };
  },
  methods: {
    getEmployee() {
      this.http.get("emps/" + this.$route.params.id).then((res) => {
        this.employee = res.data;
      });
    },
  },
  created() {
    this.getEmployee();
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('popups.profile')" /> -->
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/emps`">
            <h4 class="m-0">{{ $t("menu.menuitems.hr.subItems.emps") }}</h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $i18n.locale == "ar"
                ? "بروفايل " + employee?.name
                : employee?.name + " profile"
            }}
          </div>
        </div>
      </div>
    </div>
    <!-- start personal informations -->
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("emps.tabs.files") }}
          </span>
        </template>
        <EmployeeFiles />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("emps.tabs.working_days") }}
          </span>
        </template>
        <EmpWorkdays />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("emps.tabs.emp_salary_items") }}
          </span>
        </template>
        <EmpsSalaryItems />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("emps.tabs.emp_loans") }}
          </span>
        </template>
        <EmployeeLoans />
      </b-tab>
      <!-- <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("emps.tabs.attendance") }}
          </span>
        </template>
        <h2>hello</h2>
      </b-tab> -->
    </b-tabs>

    <!-- end personal informations -->
  </Layout>
</template>
<style scoped></style>
