<script>
import store from "@/state/store";
var state = store._modules.root._children.auth.state;

export default {
  data() {
    return {
      empFiles: [],
      empFilesType: [],
      emps: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchEmpFiles: null,
      EmpFilesSearchMode: false,
      searchModel: "",
      state: state,
      // appprices:[]
    };
  },
  methods: {
    addEmpFiles() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addfile",
        inputs: [
          {
            model: "file_date",
            type: "date",
            label: "popups.filedate",
          },

          {
            model: "emp_file_type_id",
            type: "select",
            options: (() => {
              return this.empFilesType.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.empfiletype",
          },

          {
            model: "title",
            type: "text",
            label: "popups.title",
          },

          {
            model: "file",
            type: "file",
            label: "popups.file",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = parseInt(this.$route.params.id);
              this.http.do("emp-files", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editEmpFiles(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "file_date",
            type: "date",
            label: "popups.filedate",
            value: app.file_date,
          },

          {
            model: "emp_file_type_id",
            type: "select",
            options: (() => {
              return this.empFilesType.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.empfiletype",
          },

          {
            model: "title",
            type: "text",
            label: "popups.title",
            value: app.title,
          },

          // {
          //   "model": "file",
          //   "type": "file",
          //   "label": "File",
          //   value:app.file
          // },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = parseInt(this.$route.params.id);
              this.http.put("emp-files", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updatedfile(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          {
            model: "file",
            type: "file",
            label: "popups.file",
            value: app.file,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("emp-files/update-file", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.EmpFilesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("emp-files/emp/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "title",
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.empFiles = res.data;
          console.log("######## files", this.empFiles);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.EmpFilesSearchMode = false;
      this.get(this.page);
    },
    getempfileType() {
      this.http.get("emp-file-types").then((res) => {
        this.empFilesType = res.data;
      });
    },
    deleteEmpFiles(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("emp-files", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("emp-files/emp/pagination", {
          limit: this.limit,
          page: page,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.empFiles = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getempfileType();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input
                    @keyup="search()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                    style="background-color: #2a3042 !important"
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                class="btn-close text-light"
                v-if="EmpFilesSearchMode"
                @click="cancelappsearchMode()"
              ></button>
              <button
                type="button"
                @click="addEmpFiles()"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("emp_files.id") }}</th>
                  <!-- <th scope="col">{{ $t("emp_files.filedate") }}</th> -->
                  <th scope="col">{{ $t("emp_files.type") }}</th>
                  <th scope="col">{{ $t("emp_files.title") }}</th>
                  <th scope="col">{{ $t("emp_files.file") }}</th>
                  <th scope="col">{{ $t("emp_files.created") }}</th>
                  <th scope="col">{{ $t("emp_files.updated") }}</th>
                  <th scope="col">{{ $t("emp_files.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in empFiles" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <!-- <td>{{ app.file_date?.split("T")[0] }}</td> -->
                  <td>{{ app.emp_file_type?.name }}</td>
                  <td>{{ app.title }}</td>
                  <td>
                    <a download :href="state.filesUrl + app.file">{{
                      $t("emp_files.download")
                    }}</a>
                  </td>
                  <td>{{ app.created.split("T")[0] }}</td>
                  <td>{{ app.updated.split("T")[0] }}</td>

                  <td class="d-flex">
                    <a
                      class="btn btn-primary mx-1"
                      @click="editEmpFiles(app)"
                      href="javascript: void(0);"
                      role="button"
                      >{{ $t("popups.edit") }}</a
                    >
                    <a
                      class="btn btn-primary mx-1"
                      href="javascript: void(0);"
                      role="button"
                      @click="updatedfile(app)"
                    >
                      {{ $t("popups.editfile") }}</a
                    >
                    <a
                      class="btn btn-danger"
                      href="javascript: void(0);"
                      role="button"
                      @click="deleteEmpFiles(app)"
                      >{{ $t("popups.delete") }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
